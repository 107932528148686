import React from "react";

import {PoiBusIcon, PoiEducationIcon, PoiHealthIcon, PoiShopIcon, PoiSportIcon, PoiSubwayIcon, PoiTrainIcon, PoiTramIcon} from "@pg-design/icons";
import {pluralize} from "@pg-mono/string-utils";

import {IPoi} from "../types/IPoi";

export enum PoiElementType {
    NODE = "node",
    RELATION = "relation",
    WAY = "way"
}

export enum PoiType {
    TRANSPORT = "transport",
    HEALTH = "health",
    EDUCATION = "education",
    FOOD = "food",
    SHOPS = "shops",
    SPORT = "sport",
    ENTERTAINMENT = "entertainment",
    ALL = "all",
    OFFERS = "offers",
    USER = "user"
}

export enum TransportPoiType {
    BUS = "bus",
    TRAM = "tram",
    SUBWAY = "subway",
    RAILWAY = "railway"
}

export const getPoiPluralizeLabel = (type: PoiType, value: number): string => {
    switch (type) {
        case PoiType.EDUCATION:
            return `${value} ${pluralize(["placówka edukacyjna", "placówki edukacyjne", "placówek edukacyjnych"])(value)}`;
        case PoiType.ENTERTAINMENT:
            return `${value} ${pluralize(["plac zabaw", "place zabaw", "placów zabaw"])(value)}`;
        case PoiType.FOOD:
            return `${value} ${pluralize(["kawiarnia lub restauracja", "kawiarnie i restauracje", "kawiarni i restauracji"])(value)}`;
        case PoiType.HEALTH:
            return `${value} ${pluralize(["placówka medyczna", "placówki medyczne", "placówek medycznych"])(value)}`;
        case PoiType.SHOPS:
            return `${value} ${pluralize(["sklep", "sklepy", "sklepów"])(value)}`;
        case PoiType.SPORT:
            return `${value} ${pluralize(["obiekt sportowy", "obiekty sportowe", "obiektów sportowych"])(value)}`;
        default:
            return "";
    }
};

interface IPoiUIData {
    distance: number;
    icon: React.ReactNode;
    name: string;
    title: string;
    type: PoiType;
    subType?: TransportPoiType;
}

// At this moment only PoiType.TRANSPORT has subtypes
export const getPoiUIData = (poi: IPoi | null, type: PoiType, subType?: TransportPoiType): IPoiUIData | null => {
    if (!poi) {
        return null;
    }

    const basicPoiData = {name: poi.name, distance: poi.distance, type, subType};

    const iconProps = {fillColor: "#fff", size: "1.2", wrapperSize: "2.4"};

    switch (type) {
        case PoiType.TRANSPORT:
            switch (subType) {
                case TransportPoiType.SUBWAY: {
                    return {
                        ...basicPoiData,
                        icon: <PoiSubwayIcon {...iconProps} wrapperColor="#3949ab" />,
                        title: "Metro"
                    };
                }
                case TransportPoiType.RAILWAY: {
                    return {
                        ...basicPoiData,
                        icon: <PoiTrainIcon {...iconProps} wrapperColor="#3949ab" />,
                        title: "Stacja kolejowa"
                    };
                }
                case TransportPoiType.BUS: {
                    return {
                        ...basicPoiData,
                        icon: <PoiBusIcon {...iconProps} wrapperColor="#9BD7FF" />,
                        title: "Przystanek autobusowy"
                    };
                }
                case TransportPoiType.TRAM: {
                    return {
                        ...basicPoiData,
                        icon: <PoiTramIcon {...iconProps} wrapperColor="#9BD7FF" />,
                        title: "Przystanek tramwajowy"
                    };
                }
                default: {
                    return {
                        ...basicPoiData,
                        icon: <PoiTrainIcon {...iconProps} wrapperColor="#9BD7FF" />,
                        title: "Transport"
                    };
                }
            }
        case PoiType.SHOPS:
            return {
                ...basicPoiData,
                icon: <PoiShopIcon {...iconProps} wrapperColor="#FFCDA5" />,
                title: "Sklepy"
            };
        case PoiType.EDUCATION:
            return {
                ...basicPoiData,
                icon: <PoiEducationIcon {...iconProps} wrapperColor="#B9E19B" />,
                title: "Edukacja"
            };
        case PoiType.SPORT:
            return {
                ...basicPoiData,
                icon: <PoiSportIcon {...iconProps} wrapperColor="#E1D7CD" />,
                title: "Sport"
            };
        case PoiType.HEALTH:
            return {
                ...basicPoiData,
                icon: <PoiHealthIcon {...iconProps} wrapperColor="#D7AFE1" />,
                title: "Zdrowie"
            };
        default:
            return null;
    }
};
