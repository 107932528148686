import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, catch400, getRequest, IQueryValue} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes/";

import {setGlobals} from "../../../app/actions/globals";
import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {IPropertyListProperty} from "../../../property/types/PropertyListProperty";
import {IPaginatedResponse} from "../../../request/IResponse";
import {offerDetailPropertiesListConstraints} from "../../constants/offer_detail_properties_list_constraints";
import {OfferType} from "../../types/OfferType";
import {OFFER_DETAIL_PROPERTY_LIST_DEFAULT_PAGE_SIZE} from "../constants/offer_detail_properties";

const FETCH_OFFER_DETAIL_PROPERTIES = "offer_detail/FETCH_OFFER_DETAIL_PROPERTIES";

export interface IPropertyListPropertyPlanImage {
    p_img_155x87: string;
    p_img_375x250: string;
    p_img_560: string;
    p_img_760x428: string;
    p_img_980: string;
    p_img_1500: string;
}

export interface IOfferDetailPropertiesResponse extends IPaginatedResponse {
    results: IPropertyListProperty[];
}

/**
 * Actions
 */
// offer detail properties
export const fetchOfferDetailPropertiesTypes = {
    filter: createRequestActionTypes(`${FETCH_OFFER_DETAIL_PROPERTIES}/FILTER`),
    investment: createRequestActionTypes(`${FETCH_OFFER_DETAIL_PROPERTIES}/INVESTMENT`),
    modal: createRequestActionTypes(`${FETCH_OFFER_DETAIL_PROPERTIES}/MODAL`)
};
export const resetFetchOfferDetailProperties = {
    filter: () => ({type: fetchOfferDetailPropertiesTypes.filter.reset}),
    investment: () => ({type: fetchOfferDetailPropertiesTypes.investment.reset}),
    modal: () => ({type: fetchOfferDetailPropertiesTypes.modal.reset})
};

export const fetchOfferDetailPropertiesAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    dispatch({type: fetchOfferDetailPropertiesTypes.filter.start});
    const {offer} = getState().offerDetail;
    const preferences = getState().user.preferences?.data?.filters;
    const offerType = getState().offerDetail.offer?.type;

    const url = appendQueryString(apiV2ListLink.property.list(Scenario.PROPERTY_LIST), {
        ...offerDetailPropertiesListConstraints,
        offer: offer?.id,
        page_size: OFFER_DETAIL_PROPERTY_LIST_DEFAULT_PAGE_SIZE,
        page: 1,
        rooms_0: preferences?.rooms_0,
        rooms_1: preferences?.rooms_1,
        area_0: preferences?.area_0,
        area_1: preferences?.area_1,
        ...(offerType === OfferType.FLAT ? {floor_choices: preferences?.floor_choices} : {})
    });
    return getRequest(ctx.meta, url).then((json: IOfferDetailPropertiesResponse) => {
        const result = {
            properties: json.results,
            count: json.count
        };

        dispatch(setGlobals({hasPageProjection2d: json.results.some((p) => p.external_plan_url)}));
        dispatch({type: fetchOfferDetailPropertiesTypes.filter.success, result});
        return json;
    });
};

export enum PropertyListTabTypes {
    INVESTMENT = "investment",
    FILTER = "filter",
    MODAL = "modal"
}

export interface IFetchOfferDetailPropertiesQueryValues {
    page?: number;
    sort?: string | null;
}

export const fetchOfferDetailProperties =
    (
        offerId: number,
        tab: PropertyListTabTypes,
        page_size: number,
        queryValues?: IFetchOfferDetailPropertiesQueryValues,
        filterValues: Record<string, IQueryValue> = {}
    ) =>
    async (dispatch: Dispatch): Promise<IPropertyListProperty[] | void> => {
        dispatch({type: fetchOfferDetailPropertiesTypes[tab].start, offerId});

        const sort = (queryValues && queryValues.sort) || undefined;
        const page = (queryValues && queryValues.page) || 1;

        const url = appendQueryString(apiV2ListLink.property.list(Scenario.PROPERTY_LIST), {
            ...(tab === PropertyListTabTypes.INVESTMENT ? {offer_with_properties_roi: offerId} : {offer: offerId}),
            ...filterValues,
            page_size,
            page,
            sort,
            ...offerDetailPropertiesListConstraints
        });

        return getRequest({}, url)
            .then((json: IOfferDetailPropertiesResponse) => {
                const result = {
                    properties: json.results,
                    count: json.count,
                    page_size: json.page_size || 20,
                    page: json.page || 1
                };
                dispatch({type: fetchOfferDetailPropertiesTypes[tab].success, result, offerId});
                return json.results;
            })
            .catch(
                catch400((err) => {
                    dispatch({type: fetchOfferDetailPropertiesTypes[tab].error, err, offerId});
                })
            );
    };
